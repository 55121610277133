var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');
var analyticsForm = require('../forms/analyticsForm');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');

function AppointmentSchedulingModal() {

    this.initialize = function (element, options) {
        var _this = this;

        this.brand = window.Pulte.Brand ? window.Pulte.Brand.replace("-", " ") : 'Pulte'
        this.$modalBody = this.$element.find('.AppointmentSchedulingModal__body')
        this.$leftPanel = this.$modalBody.find('.AppointmentSchedulingModal__leftPanel');
        this.initialStyle = this.$modalBody.attr('style');
        this.leftPanelInitialStyle = this.$leftPanel.attr('style');
        this.$appointmentSchedulingPanel = this.$modalBody.find('.AppointmentSchedulingModal__appointmentSchedulingPanel');
        this.$form = this.$modalBody.find('form');
        this.$communityIdField = this.$form.find('.Field-comm');
        this.$stateDropdown = this.$form.find('.AppointmentSchedulingModal__stateDropdown');
        this.$regionDropdown = this.$form.find('.AppointmentSchedulingModal__regionDropdown');
        this.$cityDropdown = this.$form.find('.AppointmentSchedulingModal__cityDropdown');
        this.$communityDropdown = this.$form.find('.AppointmentSchedulingModal__communityDropdown');
        this.$datepicker = this.$form.find('.AppointmentSchedulingModal__datepicker');
        this.$appointmentHoursDropdown = this.$form.find('.AppointmentSchedulingModal__appointmentHoursDropdown');
        this.$dateTimeUnsortedList = this.$form.find('.AppointmentSchedulingModal__dateTimeChoiceDetail');
        this.$addMoreChoiceButton = this.$form.find('.AppointmentSchedulingModal__moreChoiceButton');
        this.$buttonDivider = this.$form.find('.AppointmentSchedulingModal__buttonDivider');
        this.$chosenTimes = this.$form.find('.AppointmentSchedulingModal__chosenTimes');
        this.$thankYouPanel = this.$modalBody.find('.AppointmentSchedulingModal__thankYouPanel');
        this.$thankYouText = this.$thankYouPanel.find('.AppointmentSchedulingModal__thankYouText');
        this.$thankYouMessage = this.$thankYouPanel.find('.AppointmentSchedulingModal__thankYouMessage');
        this.$dateTimesChoiceContainer = this.$thankYouPanel.find('.AppointmentSchedulingModal__dateTimesChoiceContainer');
        this.$createAccountContainer = this.$thankYouPanel.find('.AppointmentSchedulingModal_createAccountContainer');
        this.$createAccountFirstName = this.$createAccountContainer.find('.AppointmentSchedulingModal__createAccountFirstName');
        this.$createAccountLastName = this.$createAccountContainer.find('.AppointmentSchedulingModal__createAccountLastName');
        this.$createAccountPhone = this.$createAccountContainer.find('.AppointmentSchedulingModal__createAccountPhone');
        this.$createAccountZipCode = this.$createAccountContainer.find('.AppointmentSchedulingModal__createAccountZipCode');
        this.$createAccountOutsideUS = this.$createAccountContainer.find('.AppointmentSchedulingModal__createAccountOutsideUS');
        this.$createAccountCountry = this.$createAccountContainer.find('.AppointmentSchedulingModal__createAccountCountry');
        this.$createAccountEmail = this.$createAccountContainer.find('.AppointmentSchedulingModal__username');
        this.$createAccountErrorMessage = this.$createAccountContainer.find('.AppointmentSchedulingModal__createAccountErrorMessage');
        this.$loginContainer = this.$thankYouPanel.find('.AppointmentSchedulingModal_loginContainer');
        this.$loginEmail = this.$loginContainer.find('.AppointmentSchedulingModal__username');
        this.isB2cEnabled = this.$element.data("b2cEnabled").toLowerCase() === 'true'
        this.communityId = this.$form.find('.Field-comm').attr('value');

        this.on('show.bs.modal', this.handleVisible.bind(this));

        this.$form.attach(Form)
            .on(constants.MODAL_FORM_CHANGED, this.handleFormStarted.bind(this))
            .on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmission.bind(this));
        this.dataModel = this.$element.data('appointmentDatesTimes');

        this.$stateDropdown.change(this.onStateChange.bind(this));
        this.$regionDropdown.change(this.onRegionChange.bind(this));
        this.$cityDropdown.change(this.onCityChange.bind(this));
        this.$communityDropdown.change(this.onCommunityChange.bind(this));

        this.state = this.$form.find('.Field-location-state').val();
        this.region = this.$form.find('.Field-location-region').val();
        this.cities = this.$form.find('.Field-location-city').val();

        var isCommunityPage = this.$element.data('is-community-page');
        if(this.state && isCommunityPage.toLowerCase() != 'true') {
            this.preselectState();
        }

        this.dateTimePlaceholderMsg = this.dataModel.AddChoicePlaceholderMessage;
        this.previousDateSelected = '';
        this.currentDateTime = {
            choiceLabel: '',
            month: '',
            day: '',
            year: '',
            time: ''
        };

        this.$initialDateTimeListItem = this.$element.find('.AppointmentSchedulingModal__dateTimeListItem');

        this.handleModalInit();
        this.$appointmentHoursDropdown.on('change', this.AddOrUpdateSelectedDateTime.bind(this));
        this.$addMoreChoiceButton.on('click', this.addMoreChoice.bind(this));
        this.$element.on('click', '.AppointmentSchedulingModal__deleteChoiceButton', function () {
            $(this).closest('.AppointmentSchedulingModal__dateTimeListItem').remove();

            if (_this.getActiveDateTimeListItemPosition() > 0) {
                _this.resetDateTimeChoiceLabels();
            }

            _this.$addMoreChoiceButton.removeClass('d-none');
            _this.$buttonDivider.removeClass('d-none');
            _this.updateChosenDates();
        });

        this.$form.find('.Field-select').removeClass('is-empty');
    }

    this.handleVisible = function() {
        AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_MODAL_TYPE, AdobeLaunch.FORM_APPOINTMENT_SCHEDULING_NAME, this.communityId);
    }

    this.preselectState = function() {
        this.$stateDropdown.val(this.state);
        
        $.ajax({
            url: '/API/Region/Search',
            context: this,
            data: {
                State: this.state,
                Brand: this.brand
            },
            dataType: "json",
            success: this.onStatePreselected,
            error: function(response) {
                analyticsForm.RewriteObjectForm(response);
            }
        });
    }

    this.onStatePreselected = function(data) {
        this.populateRegionDropdown(data);

        if(this.region) {
            this.preselectRegion();
        }
    }

    this.onStateChange = function() {
        this.resetDropdown(this.$communityDropdown);
        this.resetDropdown(this.$cityDropdown);
        this.resetDropdown(this.$regionDropdown);
        this.$communityDropdown.prop('disabled', true);
        this.$cityDropdown.prop('disabled', true);
        this.$regionDropdown.prop('disabled', true);
        this.getRegions();
    }

    this.preselectRegion = function() {
        this.$regionDropdown.val(this.region);

        $.ajax({
            url: '/API/City/Search',
            context: this,
            data: {
                State: this.state,
                Region: this.region,
                Brand: this.brand
            },
            dataType: "json",
            success: this.onRegionPreselected,
            error: function(response) {
                analyticsForm.RewriteObjectForm(response);
            }
        });
    }

    this.onRegionPreselected = function(data) {
        this.populateCityDropdown(data);

        if(this.cities) {
            this.preselectCities();
        } else {
            this.getCommunities();
        }
    }

    this.getRegions = function() {
        var stateValue = this.$stateDropdown.val();

        if(stateValue) {
            $.ajax({
                url: '/API/Region/Search',
                context: this,
                data: {
                    State: stateValue,
                    Brand: this.brand
                },
                dataType: "json",
                success: this.onGetRegionsSuccess,
                error: function(response) {
                    analyticsForm.RewriteObjectForm(response);
                }
            });
        }
    }

    this.onGetRegionsSuccess = function(data) {
        this.populateRegionDropdown(data);
    }

    this.populateRegionDropdown = function(data) {
        if(data && data.length) {
            for(var i = 0; i < data.length; i++) {
                var option = document.createElement('option');
                option.value = data[i].Name;
                option.text = data[i].Name;
                this.$regionDropdown.append(option);
            }
        }

        this.$regionDropdown.prop('disabled', false);
    }

    this.onRegionChange = function() {
        this.resetDropdown(this.$communityDropdown);
        this.resetDropdown(this.$cityDropdown);
        this.$communityDropdown.prop('disabled', true);
        this.$cityDropdown.prop('disabled', true);
        this.getCities();
        this.getCommunities();
    }

    this.getCities = function() {
        var stateValue = this.$stateDropdown.val();
        var regionValue = this.$regionDropdown.val();

        if(stateValue && regionValue) {
            $.ajax({
                url: '/API/City/Search',
                context: this,
                data: {
                    State: stateValue,
                    Region: regionValue,
                    Brand: this.brand
                },
                dataType: "json",
                success: this.onGetCitiesSuccess,
                error: function(response) {
                    analyticsForm.RewriteObjectForm(response);
                }
            });
        }
    }

    this.onGetCitiesSuccess = function(data) {
        this.populateCityDropdown(data);
    }

    this.populateCityDropdown = function(data) {
        if(data && data.length) {
            for(var i = 0; i < data.length; i++) {
                var option = document.createElement('option');
                option.value = data[i].Name;
                option.text = data[i].Name;
                this.$cityDropdown.append(option);
            }
        }

        this.$cityDropdown.prop('disabled', false);
    }

    this.preselectCities = function() {
        var cityArray = this.cities.split(',');

        if(cityArray.length === 1) {
            this.$cityDropdown.val(this.cities);
        } else if(cityArray.length > 1) { 
            var $options = this.$cityDropdown.find('option')
            $options.each(function() {
                if(!this.value) {
                    this.selected = false;
                }
                if(cityArray.indexOf(this.text) != -1) {
                    this.selected = true
                }
            });
        }

        this.getCommunities();
    }

    this.onCityChange = function() {
        this.resetDropdown(this.$communityDropdown);
        this.$communityDropdown.prop('disabled', true);
        this.getCommunities();
    }

    this.getCommunities = function() {
        var stateValue = this.$stateDropdown.val();
        var regionValue = this.$regionDropdown.val();
        var cityValue = this.$cityDropdown.val().join();
        
        if(stateValue && regionValue) {
            $.ajax({
                url: '/API/Community/SearchWithValidAppointmentSchedulingHours',
                context: this,
                data: {
                    State: stateValue,
                    Region: regionValue,
                    CityNames: cityValue,
                    Brand: this.brand
                },
                dataType: 'json',
                success: this.onGetCommunitiesSuccess,
                error: function(response) {
                    analyticsForm.RewriteObjectForm(response);
                }
            });
        }
    }

    this.onGetCommunitiesSuccess = function(data) {
        if(data && data.length) {
            for(var i = 0; i < data.length; i++) {
                var option = document.createElement('option');
                option.value = data[i].id;
                option.text = data[i].name;
                this.$communityDropdown.append(option);
            }
        }

        this.$communityDropdown.prop('disabled', false);
    }

    this.onCommunityChange = function() {
        var communityId = this.$communityDropdown.val();
        var datasourceId = this.$element.data('datasourceId');
        
        if(communityId && datasourceId) {
            if(this.$communityIdField) {
                this.$communityIdField.val(communityId);
            }

            $.ajax({
                url: '/API/Community/GetCommunityAppointmentScheduling',
                context: this,
                data: {
                    CommunityId: communityId,
                    DatasourceId: datasourceId
                },
                dataType: 'json',
                success: this.onGetAppointmentSchedulingSuccess,
                error: function(response) {
                    analyticsForm.RewriteObjectForm(response);
                }
            });
        }
    }

    this.onGetAppointmentSchedulingSuccess = function(result) {
        if(result) {
            this.dataModel = result;
            this.handleModalInit();
        }
    }

    this.resetDropdown = function($dropdown) {
        var $defaultOption = $dropdown.children().first().clone();
        $dropdown.empty();
        $defaultOption.appendTo($dropdown);
    }

    this.handleModalInit = function () {
        this.$initialDateTimeListItem.remove();

        this.previousDateSelected = this.dataModel.defaultDate;

        if (this.dataModel) {
            var d = new Date(this.dataModel.DefaultDate);
            this.setAppointmentHoursDropdown(d.getDay());
            this.setCurrentDateTime(utils.getMonthName(d.getMonth()), d.getDate(), d.getFullYear(), this.getActiveDateTimeListItemPosition());

            this.$datepicker.datepicker({
                inline: true,
                minDate: this.dataModel.MinDate,
                maxDate: this.dataModel.MaxDate,
                defaultDate: this.dataModel.DefaultDate,
                beforeShowDay: this.enableDay.bind(this),
                onSelect: function (date, instance) {
                    var d = new Date(date);

                    var dateChanged = this.previousDateSelected === date ? false : true;

                    if (dateChanged) {
                        this.$form.trigger(constants.MODAL_FORM_CHANGED);
                        this.previousDateSelected = date;

                        this.setCurrentDateTime(utils.getMonthName(d.getMonth()), d.getDate(), d.getFullYear(), this.getActiveDateTimeListItemPosition());
                        this.setAppointmentHoursDropdown(d.getDay());
                        this.$appointmentHoursDropdown.focus();

                        //this is to remove the appointment detail if there is only 1 selection and the user changes the day on the calendar
                        if ((this.getActiveDateTimeListItemPosition() - 1) === 0 && this.getActiveAppointmentListItem()) {
                            this.removeCurrentDateTimeSelection();
                        }
                        else if (this.getActiveDateTimeListItemPosition() > 0 && this.currentDateTimeSelectionValid()) {
                            this.resetCurrentDateTimeChoiceValue();
                        }
                        this.updateChosenDates();
                    }
                    else {
                        this.$appointmentHoursDropdown.focus();
                    }
                }.bind(this)
            });
        }

        this.$modalBody.find('.AppointmentSchedulingModal__closeButton').on('click', this.resetModal.bind(this));
    };

    this.enableDay = function (date) {
        if (this.dataModel) {
            var currentDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();//getMonth() method returns the month (0 to 11) for a specified date that's why I'm adding 1   
            var isNotDisabledDay = this.dataModel.DisabledDays.indexOf(currentDate) === -1; //using indexOf instead of contains, to support IE 11
            var isNotClosedDay = this.dataModel.ClosedDays.indexOf(date.getDay()) === -1;
            return [(isNotClosedDay && isNotDisabledDay)];
        }
    };

    this.setAppointmentHoursDropdown = function (dayIndex) {

        if (this.dataModel) {
            switch (dayIndex) {
                case 0:
                    this.setHoursDropdownOptions(this.dataModel.SundaySchedule);
                    break;
                case 1:
                    this.setHoursDropdownOptions(this.dataModel.MondaySchedule);
                    break;
                case 2:
                    this.setHoursDropdownOptions(this.dataModel.TuesdaySchedule);
                    break;
                case 3:
                    this.setHoursDropdownOptions(this.dataModel.WednesdaySchedule);
                    break;
                case 4:
                    this.setHoursDropdownOptions(this.dataModel.ThursdaySchedule);
                    break;
                case 5:
                    this.setHoursDropdownOptions(this.dataModel.FridaySchedule);
                    break;
                case 6:
                    this.setHoursDropdownOptions(this.dataModel.SaturdaySchedule);
                    break;
            }
        }
    }

    this.setHoursDropdownOptions = function (appointmentHours) {
        this.$appointmentHoursDropdown.find('option:gt(0)').remove();

        if (appointmentHours) {
            for (var i = 0; i < appointmentHours.length; i++) {
                var option = document.createElement('option');
                option.setAttribute('value', appointmentHours[i]);
                option.innerText = appointmentHours[i];
                this.$appointmentHoursDropdown.append(option);
            }
        }
    }

    this.setCurrentDateTime = function (month, day, year, position, time) {

        if (this.getActiveDateTimeListItemPosition() <= 3 && this.getActiveDateTimeListItemPosition() >= 0 && this.dataModel.DateTimeChoiceLabels && this.dataModel.DateTimeChoiceLabels.length) {

            this.currentDateTime = {
                choiceLabel: this.dataModel.DateTimeChoiceLabels[position],
                month: month,
                day: day,
                year: year,
                time: time
            };
        }
    }

    this.getCurrentDateTime = function () {
        var appointmentTime = this.$appointmentHoursDropdown.find(':selected').text();
        return this.currentDateTime.month + ' ' + this.currentDateTime.day + ', ' + this.currentDateTime.year + ' ' + appointmentTime;
    }

    this.AddOrUpdateSelectedDateTime = function () {
        var firstOption = this.$appointmentHoursDropdown.find('option:first').text();
        var appointmentTime = this.$appointmentHoursDropdown.find(':selected').text();

        if (firstOption !== appointmentTime) {//making sure that the selection is not the default one (Please select...)

            if (this.getActiveDateTimeListItemPosition() === 0 && this.currentDateTimeSelectionValid()) {//adding first choice
                this.setCurrentDateTime(this.currentDateTime.month, this.currentDateTime.day, this.currentDateTime.year, this.getActiveDateTimeListItemPosition(), appointmentTime);
                this.addNewAppointmentDateTime(this.currentDateTime.choiceLabel, this.currentDateTime.month, this.currentDateTime.day, this.currentDateTime.year, appointmentTime);
                var $selectedDateTimeListItem = this.getActiveAppointmentListItem();
                this.removeDateTimeInitClass($selectedDateTimeListItem);
            }
            else {//updating choices
                var $existingDateTimeListItems = this.$dateTimeUnsortedList.find('.AppointmentSchedulingModal__dateTimeListItem');
                var currentDateTimeSelection = this.getCurrentDateTime();
                var listAlreadyContainsChoice = false;

                for (var i = 0; i < $existingDateTimeListItems.length; i++) {
                    var alreadyExistingDateTime = $($existingDateTimeListItems[i]).find('.AppointmentSchedulingModal__dateTimeChoice').text();

                    if (currentDateTimeSelection === alreadyExistingDateTime) {
                        listAlreadyContainsChoice = true;
                        this.slideUpFadeIn($($existingDateTimeListItems[i]));//making sure the user sees that the choice is already on the list
                        break;
                    }
                }

                if (!listAlreadyContainsChoice) {
                    this.updateSelectedAppointmentDateTime(appointmentTime);
                }
                else {
                    this.$appointmentHoursDropdown.find('option:eq(0)').prop('selected', true);//reseting dropdown to first element
                    this.$appointmentHoursDropdown.focus();
                }
            }
        }
        else {
            this.$appointmentHoursDropdown.focus();
        }

        this.updateChosenDates();
    }

    this.addNewAppointmentDateTime = function (choiceLabel, month, day, year, appointmentTime) {

        if (this.getActiveDateTimeListItemPosition() < 3 && this.currentDateTimeSelectionValid()) {
            var newDateTimeListItem = this.getNewListItem(choiceLabel, month, day, year, appointmentTime);
            this.$dateTimeUnsortedList.append(newDateTimeListItem);
        }
        else {
            this.$appointmentHoursDropdown.focus();
        }
    }

    this.updateSelectedAppointmentDateTime = function (appointmentTime) {
        this.setCurrentDateTime(this.currentDateTime.month, this.currentDateTime.day, this.currentDateTime.year, this.getActiveDateTimeListItemPosition(), appointmentTime);

        if (this.$dateTimeUnsortedList.length > 0) {

            var $selectedDateTime = this.getActiveAppointmentListItem();
            var $dateTimeData = $selectedDateTime.find('.AppointmentSchedulingModal__dateTimeChoice');

            $dateTimeData.text(this.currentDateTime.month + ' ' + this.currentDateTime.day + ', ' + this.currentDateTime.year + ' ' + appointmentTime);
            this.removeDateTimeInitClass($selectedDateTime);
        }
    }

    this.getNewListItem = function (choiceLabel, month, day, year, time) {

        if (choiceLabel && month && day && year && time) {
            var $newListItem = this.$initialDateTimeListItem.clone();

            $newListItem.find('.AppointmentSchedulingModal__dateTimeLabel').text(choiceLabel);
            $newListItem.find('.AppointmentSchedulingModal__dateTimeChoice').text(month + ' ' + day + ', ' + year + ' ' + time);

            return $newListItem;
        }

        return '';
    }

    this.removeCurrentDateTimeSelection = function () {

        if (this.getActiveAppointmentListItem()) {
            var listItem = this.$dateTimeUnsortedList.find('.AppointmentSchedulingModal__dateTimeListItem:first');

            if (listItem.length > 0) {
                listItem.remove();
            }
        }
    };

    this.getActiveAppointmentListItem = function () {
        var listItem = this.$dateTimeUnsortedList.find('.AppointmentSchedulingModal__dateTimeListItem').eq(this.getActiveDateTimeListItemPosition() - 1);

        if (listItem.length) {
            return listItem;
        }

        return null;
    }

    this.addMoreChoice = function () {

        if (this.getActiveDateTimeListItemPosition() > 0) {

            if (this.getActiveDateTimeListItemPosition() < 3) {

                this.setCurrentDateTime(this.currentDateTime.month, this.currentDateTime.day, this.currentDateTime.year, this.getActiveDateTimeListItemPosition());

                this.addNewAppointmentDateTime(this.currentDateTime.choiceLabel, '-', '-', '-', '-');
                this.resetCurrentDateTimeChoiceValue();
                this.$appointmentHoursDropdown.find('option:first').prop('selected', true);//reseting dropdown to first element
                this.$appointmentHoursDropdown.focus();
            }

            if (this.getActiveDateTimeListItemPosition() === 3) {
                this.$addMoreChoiceButton.addClass('d-none');
                this.$buttonDivider.addClass('d-none');
            }
        }
        else {
            this.$appointmentHoursDropdown.focus();
        }
    }

    // making sure we always have the right label order after deleting a selected dateTime option
    this.resetDateTimeChoiceLabels = function () {

        if (this.dataModel.DateTimeChoiceLabels && this.dataModel.DateTimeChoiceLabels.length) {
            var $selectedDateTimeListItems = this.$dateTimeUnsortedList.find('.AppointmentSchedulingModal__dateTimeListItem');

            for (var i = 0; i < $selectedDateTimeListItems.length; i++) {
                $($selectedDateTimeListItems[i]).find('.AppointmentSchedulingModal__dateTimeLabel').text(this.dataModel.DateTimeChoiceLabels[i]);
            }
        }
    }

    this.resetCurrentDateTimeChoiceValue = function () {
        var $selectedItem = this.getActiveAppointmentListItem()
        var $activeDateTime = $selectedItem.find('.AppointmentSchedulingModal__dateTimeChoice');

        this.addDateTimeInitClass($selectedItem);
        $activeDateTime.text(this.dateTimePlaceholderMsg);
    }

    this.slideUpFadeIn = function ($dateTimeChoice) {
        $dateTimeChoice.slideUp(300).fadeIn(400);
    }

    this.getActiveDateTimeListItemPosition = function () {
        return this.$dateTimeUnsortedList.find('.AppointmentSchedulingModal__dateTimeListItem').length;
    }

    this.removeDateTimeInitClass = function ($listItem) {

        if ($listItem) {
            $listItem.removeClass('AppointmentSchedulingModal__dateTimeInit');
        }
    }

    this.addDateTimeInitClass = function ($listItem) {

        if ($listItem) {
            $listItem.addClass('AppointmentSchedulingModal__dateTimeInit');
        }
    }

    this.currentDateTimeSelectionValid = function () {
        var check = false;

        if (this.getActiveDateTimeListItemPosition() === 0) {//does not apply for the first item
            check = true;
        }
        else {
            var hasDateTimeInit = this.getActiveAppointmentListItem().hasClass('AppointmentSchedulingModal__dateTimeInit');

            if (!hasDateTimeInit) {
                check = true;
            }
            else {
                check = false;
            }
        }

        return check;
    }

    this.updateChosenDates = function () {
        var chosenTimes = '';
        var $listItems = this.$dateTimeUnsortedList.find('.AppointmentSchedulingModal__dateTimeChoice');

        if ($listItems) {
            $listItems.each(function (index) {
                if (!isNaN(new Date(this.innerText))) {
                    if (index > 0) {
                        chosenTimes += '\n';
                    }
                    chosenTimes += this.innerText;
                }
            });
        }

        this.$chosenTimes.val(chosenTimes);
        this.$chosenTimes.blur();
    }

    this.handleFormStarted = function (event, form) {
        this.$form.off(constants.MODAL_FORM_CHANGED);
        AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_MODAL_TYPE, AdobeLaunch.FORM_APPOINTMENT_SCHEDULING_NAME, this.communityId);
    }

    this.handleFormSubmission = function (event, form) {
        var isAppointmentSchedulingForm = $(event.target).hasClass('AppointmentSchedulingModal__form');
        var isCreateAccountForm = $(event.target).hasClass('AppointmentSchedulingModal__createAccountForm');
        var $dateItems = this.$dateTimeUnsortedList.find('.AppointmentSchedulingModal__dateTimeChoice');
        var selectedDaysCount = 0;
        if ($dateItems) {
            selectedDaysCount = $dateItems.length;
        }

        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: 'json',
            context: this,
            success: function (response) {
                if (isAppointmentSchedulingForm) {
                    if (response && response.success) {
                        AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_MODAL_TYPE, AdobeLaunch.FORM_APPOINTMENT_SCHEDULING_NAME, form.data.CommunityIds, null, null, selectedDaysCount)
                        this.switchToThankYouScreen(form, response.userExists);
                    }
                } else {
                    if (isCreateAccountForm) {
                        AdobeLaunch.pushUserEvent(AdobeLaunch.USER_REGISTERED_EVENT_NAME);
                    } else {
                        if (response.UserId) {
                            AdobeLaunch.pushUserEvent(AdobeLaunch.USER_SIGNED_IN_EVENT_NAME, response.UserId);
                        }
                    }
                    location.href = response.RedirectUrl;
                }
                analyticsForm.RewriteObjectForm(response, event);
            },
            error: function (response) {
                analyticsForm.RewriteObjectForm(response, event);
                AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_MODAL_TYPE, AdobeLaunch.FORM_APPOINTMENT_SCHEDULING_NAME);
            }
        });
    }

    this.switchToThankYouScreen = function (form, userExists) {
        var backgroundColor = this.$modalBody.data('thank-you-background-color');
        this.$modalBody.attr('style', 'background-color: ' + backgroundColor + ';');
        var textColor = this.$leftPanel.data('thank-you-text-color');
        this.$leftPanel.attr('style', 'color: ' + textColor + ';');
        this.$appointmentSchedulingPanel.addClass('d-none');
        this.$thankYouPanel.removeClass('d-none');
        this.$thankYouText.html(function (index, html) {
            return html.replace('{firstName}', form.data.FirstName);
        });
        
        this.$dateTimeListClone = this.$dateTimeUnsortedList.clone();
        this.$dateTimeListClone.find('button').remove();
        this.$dateTimeListClone.find('li').removeClass('justify-content-between').addClass('justify-content-center');
        this.$dateTimesChoiceContainer.append(this.$dateTimeListClone);

        if(this.isB2cEnabled){
            this.showB2CLogIn()
        }else{
            if (userExists) {
                this.showLogIn(form.data);
            } else {
                this.showCreateAccount(form.data);
            }
        }

    }

    this.showCreateAccount = function (formData) {
        this.$createAccountContainer.removeClass('d-none');
        this.$createAccountFirstName.val(formData.FirstName);
        this.$createAccountLastName.val(formData.LastName);
        this.$createAccountPhone.val(formData.Phone);
        this.$createAccountZipCode.val(formData.ZipCode);
        this.$createAccountOutsideUS.val(formData.OutsideUS);
        this.$createAccountCountry.val(formData.Country);
        this.$createAccountEmail.val(formData.Email);
    }

    this.showLogIn = function (formData) {
        this.$loginContainer.removeClass('d-none');
        this.$loginEmail.val(formData.Email);
    }

    this.showB2CLogIn = function (){
        
        this.$createAccountContainer.removeClass('d-none')
            .find(".AppointmentSchedulingModal__createAccountForm")
                .prop('name', '')
            .prop('action', '/auth/b2c/signinsignupprefill')
                .end()
            .find('.Field-Group')
                .addClass('d-none')
                .end()
            .find('.password')
                .prop('disabled', true)
                .end()
            .find('.Field')
                .addClass('d-none')
                .end()
            .find('.AppointmentSchedulingModal__button')
                .val("Login or Create an Account")
                .prop('disabled', false)
                .parent()
                    .removeClass("col-md-6 col-lg-4"); 

    }

    this.resetModal = function () {
        this.$createAccountContainer.addClass('d-none');
        this.$loginContainer.addClass('d-none');
        this.$thankYouPanel.addClass('d-none');
        this.$appointmentSchedulingPanel.removeClass('d-none');
        this.$modalBody.attr('style', this.initialStyle);
        this.$leftPanel.attr('style', this.leftPanelInitialStyle);
        this.$dateTimeUnsortedList.find('li').each(function () {
            $(this).find('.AppointmentSchedulingModal__deleteChoiceButton').click();
        });
        this.$datepicker.datepicker('setDate', this.dataModel.DefaultDate);
        this.setAppointmentHoursDropdown(new Date(this.dataModel.DefaultDate).getDay());
        this.$dateTimesChoiceContainer.empty();
    }
}

module.exports = component(AppointmentSchedulingModal);
